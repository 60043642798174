import { useContext, useMemo } from 'react';
import { filter, find, isEmpty } from 'lodash';
import { IGoogleMapPointListProps } from '@/components/base/google-map/point-list/types';
import GoogleMapPointListItem from '@/components/base/google-map/point-list/item';
import GoogleMapPointListToolbar from '@/components/base/google-map/point-list/toolbar';
import { GoogleMapContext } from '../Context';
import { PointProps } from '../types';
import NoRecords from '../../no-records';
import { motion } from 'framer-motion';

const animations = {
  items: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.3,
        staggerChildren: 0.1,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const PlanYourTripListWaypoints: React.FC<IGoogleMapPointListProps> = ({
  points,
  showToolbar,
}) => {
  const {
    showPointList,
    selectedRoutePoints,
    viewMyRoutes,
    togglePointToRoute,
  } = useContext(GoogleMapContext);

  // data
  const formattedPoints = useMemo(
    () => filter(points, (point: any) => !isEmpty(point.title)),
    [points, viewMyRoutes]
  );
  const filteredPoints = useMemo(() => {
    if (viewMyRoutes) {
      return filter(
        formattedPoints,
        (point) => !!find(selectedRoutePoints, { id: point.id })
      );
    }

    return formattedPoints;
  }, [formattedPoints, selectedRoutePoints, viewMyRoutes]);

  // actions
  const handleTogglePointAddToRoute = (point: PointProps) => {
    if (!point) {
      return;
    }

    togglePointToRoute(point);
  };

  // renders
  if (!showPointList || isEmpty(formattedPoints)) {
    return null;
  }

  return (
    <div className="google-map-point-list">
      {showToolbar && <GoogleMapPointListToolbar points={formattedPoints} />}
      <div className="google-map-point-list-scrollable">
        <motion.div
          variants={animations.items}
          className="google-map-point-list-items"
        >
          {filteredPoints.map((point: any, index) => (
            <GoogleMapPointListItem
              key={index}
              point={point}
              showActions={showToolbar}
              onToggleAddRoute={handleTogglePointAddToRoute}
            />
          ))}
          {isEmpty(filteredPoints) && (
            <NoRecords
              title="My Routes"
              description="There are no selected points on the route yet"
            />
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default PlanYourTripListWaypoints;
