import { useContext } from 'react';
import Icon from '@/components/base/icon';
import { GoogleMapContext } from '../../Context';
import { clsx } from '@/utils';
import { IPointListToolbarProps } from './types';

const GoogleMapPointListToolbar: React.FC<IPointListToolbarProps> = ({
  points = [],
}) => {
  const {
    selectedRoutePoints,
    viewMyRoutes,
    setViewMyRoutes,
    resetSelectedPoints,
  } = useContext(GoogleMapContext);

  // actions
  const handleToggleViewMyRoutes = () => {
    setViewMyRoutes((prevStatus) => !prevStatus);
  };

  const handleClickViewMyRoutes = () => {
    setViewMyRoutes(true);
  };

  const handleClear = () => {
    resetSelectedPoints();

    if (viewMyRoutes) {
      handleToggleViewMyRoutes();
    }
  };

  return (
    <div className="google-map-point-list-toolbar">
      <div className="google-map-point-list-toolbar-group">
        <button
          className={clsx(
            'google-map-point-list-toolbar-button google-map-point-list-toolbar-button--hero',
            {
              'google-map-point-list-toolbar-button--hero-reversed':
                viewMyRoutes,
            }
          )}
          onClick={handleToggleViewMyRoutes}
        >
          <div className="google-map-point-list-toolbar-button-badge">
            {viewMyRoutes ? (
              <span>{points.length}</span>
            ) : (
              <span>{selectedRoutePoints.length}</span>
            )}
          </div>
          {viewMyRoutes ? <span>All Points</span> : <span>View my route</span>}
        </button>
      </div>
      <div className="google-map-point-list-toolbar-bracket" />
      <div className="google-map-point-list-toolbar-group">
        <button
          className="google-map-point-list-toolbar-button"
          onClick={handleClickViewMyRoutes}
        >
          <Icon name="icon-heart" />
        </button>
        <button className="google-map-point-list-toolbar-button">
          <Icon name="icon-share" />
        </button>
      </div>
      <div className="google-map-point-list-toolbar-bracket" />
      <div
        className="google-map-point-list-toolbar-group"
        onClick={handleClear}
      >
        <button className="google-map-point-list-toolbar-button">Clear</button>
      </div>
    </div>
  );
};

export default GoogleMapPointListToolbar;
