import React, { useContext } from 'react';
import Image from '@/components/base/image';
import Icon from '@/components/base/icon';
import { IGoogleMapPointListItem } from '@/components/base/google-map/point-list/item/types';
import { functions } from '@wap-client/core';
import { isEmpty } from 'lodash';
import Anchor from '@/components/base/anchor';
import { GoogleMapContext } from '../../Context';

const GoogleMapPointListItem: React.FC<IGoogleMapPointListItem> = ({
  point,
  showActions,
  onToggleAddRoute,
}) => {
  const { isPointSelected } = useContext(GoogleMapContext);

  // data
  const isAdded = isPointSelected(point);

  // actions
  const onClickToggleAddRoute = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onToggleAddRoute && onToggleAddRoute(point);
  };

  return (
    <Anchor
      className="google-map-point-list-items-item"
      motion={{
        initial: 'hidden',
        whileInView: 'show',
        viewport: { once: true },
      }}
      {...point.anchor}
    >
      {point.thumbnail && (
        <div className="google-map-point-list-items-item-image">
          <Image source={point.thumbnail} />
        </div>
      )}
      <div className="google-map-point-list-items-item-content">
        <div className="google-map-point-list-items-item-content-body">
          {!isEmpty(point.subtitle) && (
            <div className="google-map-point-list-items-item-content-body-subtitle">
              {point.subtitle}
            </div>
          )}
          {!isEmpty(point.title) && (
            <div className="google-map-point-list-items-item-content-body-title">
              {point.title}
            </div>
          )}
        </div>
        {showActions && (
          <button
            className={functions.classnames(
              'google-map-point-list-items-item-button',
              {
                'google-map-point-list-items-item-button--danger': isAdded,
              }
            )}
            onClick={onClickToggleAddRoute}
          >
            <Icon name={isAdded ? 'icon-close-2' : 'icon-add-2'} />
            {isAdded ? (
              <span>Remove Your Route</span>
            ) : (
              <span>Add Your Route</span>
            )}
          </button>
        )}
      </div>
    </Anchor>
  );
};

export default GoogleMapPointListItem;
