import { useContext, useEffect } from 'react';
import useDeviceType from '@/hooks/useDeviceType';
import Icon from '@/components/base/icon';
import { IIGoogleMapTopbarProps } from '@/components/base/google-map/topbar/types';
import { GoogleMapContext } from '@/components/base/google-map/Context';

const GoogleMapTopbar: React.FC<IIGoogleMapTopbarProps> = () => {
  const deviceType = useDeviceType();
  const { showPointList, setShowPointList } = useContext(GoogleMapContext);

  // const hasAnyRouteWaypoints = useMemo(
  //   () => !isEmpty(route?.pois),
  //   [route?.pois?.length]
  // );

  const onToggleShowPointList = () => {
    setShowPointList((prevState) => !prevState);
  };

  useEffect(() => {
    if (deviceType === 'desktop') {
      setShowPointList(true);
    }
  }, [deviceType]);

  return (
    <div className="google-map-topbar">
      {/* {addedRoutesIds.length > 0 && (
        <div className="google-map-topbar-item">
          <button className="google-map-topbar-button google-map-topbar-button--active">
            <div className="google-map-topbar-button-badge">
              {addedRoutesIds.length}
            </div>
            My Route
          </button>
        </div>
      )} */}
      {deviceType !== 'desktop' && (
        <div className="google-map-topbar-item" onClick={onToggleShowPointList}>
          <button className="google-map-topbar-button">
            <Icon name={showPointList ? 'icon-close-2' : 'icon-see-list'} />
            {showPointList ? <span>Hide list</span> : <span>See list</span>}
          </button>
        </div>
      )}
    </div>
  );
};

export default GoogleMapTopbar;
