import React from 'react';
import type { NoRecordsProps } from './types';
import { isBoolean, isNil, isNumber } from 'lodash';
import { clsx } from '@/utils';
import Icon from '../icon';
import CustomButton from '@/components/widgets/custom-button';
import { motion } from 'framer-motion';
import { useApp } from '@wap-client/core';

const DEFAULT_ICON = 'icon-error-outline';
const DEFAULT_TITLE = 'No Records Yet';
const DEFAULT_DESCRIPTION = 'This section does not have any records yet.';

const animations = {
  container: {
    hidden: {
      opacity: 0,
      y: 100,
    },
    show: {
      opacity: 1,
      y: 0,
    },
  },
};

const NoRecords: React.FunctionComponent<NoRecordsProps> = ({
  display = 3,
  title = true,
  color = 'black',
  icon = 'icon-error-outline',
  iconClasses,
  description = true,
  centered = true,
  stretch = true,
  search,
  onClearSearch,
  children,
}) => {
  const app = useApp();

  // renders
  const renderIcon = () => {
    // dont't show
    if (icon === false) {
      return null;
    }

    return (
      <Icon
        name={icon === true ? DEFAULT_ICON : icon || DEFAULT_ICON}
        className={clsx('no-records-icon', iconClasses)}
      />
    );
  };

  const renderTitle = () => {
    // dont't show
    if (isNil(title) || title === false) {
      return null;
    }

    return (
      <div className="no-records-title">
        {isBoolean(title) ? app.settings.translations['no-records-yet'] : title}
      </div>
    );
  };

  const renderDescription = () => {
    // dont't show
    if (isNil(description) || description === false) {
      return null;
    }

    return (
      <div className="no-records-description">
        {isBoolean(description)
          ? app.settings.translations['no-records-yet-information']
          : description}
      </div>
    );
  };

  const renderSearch = () => {
    if (search) {
      return (
        <CustomButton className="no-records-button" onClick={onClearSearch}>
          Clear Search
        </CustomButton>
      );
    }
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="show"
      viewport={{ once: true }}
      variants={animations.container}
      className={clsx('no-records', {
        'no-records--stretch': stretch,
        'no-records--centered': centered,
        [`no-records--color-${color}`]: color,
        [`no-records--display-${display}`]: isNumber(display),
      })}
    >
      {renderIcon()}
      {renderTitle()}
      {renderDescription()}
      {renderSearch()}
      {children}
    </motion.div>
  );
};

export default NoRecords;
